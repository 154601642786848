<template>
  <div>
    <v-app>
      <v-card>
        <v-card-title>
          Marcas
          <v-spacer></v-spacer>
          
          <v-btn class 
          @click="$emit('openBulkUploadBrands')"
          text icon color="blue">
            <v-icon style="color: blue !important;">icon-file_upload</v-icon>
          </v-btn>

          <v-btn class @click="openDialogBrandEdit" text icon color="blue">
            <v-icon>mdi-plus</v-icon>
          </v-btn>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class @click="exportExcel" text icon color="green">
                <v-icon>mdi-file-excel</v-icon>
              </v-btn>
            </template>
            <span>Exportar a Excel</span>
          </v-tooltip>
          <v-btn class @click="fetchData" text icon color="blue">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-card-text >
              <div class="d-flex justify-space-between mb-4">
              <v-text-field
                  v-model="search"
                  clearable
                  label="Buscar"
                  hide-details
                  dense
                  solo
                  @change="fetchData"

                  class="mr-3"
              ></v-text-field>
              <v-btn class="btn-starkoms-primary" style="min-width: 138px;" dark>Buscar</v-btn>
              </div>
          </v-card-text>
        </v-card-text>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="tableData"
            :page.sync="page"
            :items-per-page="selectItemsPerPage"
            :loading="dataTableLoading"
            no-results-text="No hay resultados disponibles"
            no-data-text="No hay datos disponibles"
            loading-text="Cargando datos..."
            class="elevation-1 datatable"
            :disable-pagination="true"
            :hide-default-footer="true"
          >
          <template v-slot:item.created_at="{ item }">
          {{$moment(item.created_at).format('LLLL')}}
          </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      style="font-size: 1.5rem !important"
                      small
                      color="#8c8c8c"
                      class="mr-2"
                      v-on="on"
                      @click="openDialogBrandEdit({brand_id: item.id, brand_name: item.name})"
                       >mdi-circle-edit-outline</v-icon>
                  </template>
                  <span>Editar Marca</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      style="font-size: 1.5rem !important"
                      small
                      color="#8c8c8c"
                      class="mr-2"
                      v-on="on"
                      @click="softDelete(item)"
                    >mdi-package-down</v-icon>
                  </template>
                  <span>Archivar</span>
                </v-tooltip>

            </template>
            
          </v-data-table>
          <div class="d-flex justify-space-between mt-4">
            <v-select
              style="max-width: 120px"
              @input="fetchData"
              v-model="selectItemsPerPage"
              label="Registros por página"
              :items="[5,10,20,50,100, 500]"
            ></v-select>
            <v-pagination
              @input="fetchData"
              v-model="page"
              :length="totalPages"
              :total-visible="100"
              color="#e55d43"
            ></v-pagination>
          </div>
           
        </v-card-text>
      </v-card>
    </v-app>
    <dialog-brand-edit  @success="fetchData"></dialog-brand-edit>
    <bulk-upload-brands @success="fetchData"></bulk-upload-brands>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import DialogBrandEdit from "@/views/oms/Inventario/DialogBrandEdit";
import BulkUploadBrands from '@/views/oms/Inventario/BulkUploadBrands';

// import EventBus from "@/event-bus";
export default {
  components: {
    DialogBrandEdit,
    BulkUploadBrands
  },
  data() {
    return {
      dataTableLoading : false,
      page: 1,
      pageCount: 0,
      selectItemsPerPage: 10,
      totalPages: 1,
      search: "",
      selected: [],
      headers: [
        { text: "Nombre Marca", value: "name" },
        { text: "Fecha creación", value: "created_at" },
        { text: "Acciones", value: "actions" },
      ],
      headersExp: [
        { text: "Id Marca", value: "id" },
        { text: "Nombre Marca", value: "name" },
        { text: "Fecha creación", value: "created_at" },
      ],
      tableData: [],
      dateMsg:
        new Date().toISOString().substr(0, 10) +
        " " +
        new Date().toISOString().substr(11, 5),
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard Marcas", route: "brands" },
    ]);
    this.fetchData();
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      //get the store, created Product alert from crearProducto component
    //   if (localStorage.getItem("crearProducto") !== null) {
    //     this.createdProductMessage();
    //   }
    //   //get the store, created Bundle alert from crearProducto component
    //   if (localStorage.getItem("crearBundle") !== null) {
    //     this.createdBundleMessage();
    // }
    },
    fetchData() {
      var vm = this;
      vm.dataTableLoading = true;

      const page = this.page;
      const itemsPerPage = this.selectItemsPerPage;
      const search = this.search;
      this.axios({
        url: "inventory/brands",
        method: "GET",
         params: {
          page,
          perPage: itemsPerPage,
          search,
        },
      })
        .then((response) => {
    
          vm.tableData = response.data.data;

          vm.totalPages = response.data.last_page;
          vm.dataTableLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exportExcel() {
      var vm = this;
      let headers = vm.headersExp.map((e) => e.text);
      let datos = vm.tableData.map(o => ['id', 'name', 'created_at'].reduce((acc, curr) => {
        acc[curr] = o[curr];
        return acc;
      }, {}));
      this.axios({
        url: "excel/from_array",
        method: "POST",
        data: {
          data: datos,
          cabeceras: headers,
        },
        responseType: "blob",
      })
        .then((response) => {
          const newBlob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          });
          const data = window.URL.createObjectURL(newBlob);
          window.open(data, "_blank");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openDialogBrandEdit(data){
        console.log(data);
        this.$emit("openDialogBrandEdit", data);
    },
    openBulkUploadBrands(data){
        console.log(data);
        this.$emit("openBulkUploadBrands", data);
    },
    softDelete(item) {
      var vm = this;
      var confirmDelete = true;

      if (confirm("Está seguro que desea archivar la marca?")) {

        this.axios({
            url: "inventory/brands/" + item.id,
            method: "DELETE",
        })
        .then((response) => {
            vm.popMessageSuccess("Marca borrada correctamente");
            vm.fetchData();
        })
        .catch((error) => {
            console.log(error);
        });
     
      }
    },
    createdProductMessage() {
    //   let vm = this;
    //   let crearProducto = JSON.parse(localStorage.getItem("crearProducto"));

    //   this.$bvToast.toast(
    //     `El Producto: ${crearProducto.name} ha sido creado, el ${vm.dateMsg} exitosamente!`,
    //     {
    //       title: `Información`,
    //       variant: "success",
    //       solid: true,
    //       toaster: "b-toaster-bottom-center",
    //     }
    //   );
    //   localStorage.removeItem("crearProducto");
    },
    createdBundleMessage() {
    //   let vm = this;
    //   let crearBundle = JSON.parse(localStorage.getItem("crearBundle"));

    //   this.$bvToast.toast(
    //     `El Bundle: ${crearBundle.name} ha sido creado, el ${vm.dateMsg} exitosamente!`,
    //     {
    //       title: `Información`,
    //       variant: "success",
    //       solid: true,
    //       toaster: "b-toaster-bottom-center",
    //     }
    //   );
    //   localStorage.removeItem("crearBundle");
    },
    popMessageSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Información`,
        variant: "success",
        solid: true,
        toaster: "b-toaster-bottom-center",
      });
    //   this.fetchData();
    },
  },
};
</script>
<style lang="scss">
.v-btn__content{
  .icon-file_upload::before{
    color: #2196F3 !important
  }
}
</style>