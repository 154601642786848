var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app',[_c('v-card',[_c('v-card-title',[_vm._v(" Marcas "),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","icon":"","color":"blue"},on:{"click":function($event){return _vm.$emit('openBulkUploadBrands')}}},[_c('v-icon',{staticStyle:{"color":"blue !important"}},[_vm._v("icon-file_upload")])],1),_c('v-btn',{attrs:{"text":"","icon":"","color":"blue"},on:{"click":_vm.openDialogBrandEdit}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"green"},on:{"click":_vm.exportExcel}},on),[_c('v-icon',[_vm._v("mdi-file-excel")])],1)]}}])},[_c('span',[_vm._v("Exportar a Excel")])]),_c('v-btn',{attrs:{"text":"","icon":"","color":"blue"},on:{"click":_vm.fetchData}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-card-text',[_c('v-card-text',[_c('div',{staticClass:"d-flex justify-space-between mb-4"},[_c('v-text-field',{staticClass:"mr-3",attrs:{"clearable":"","label":"Buscar","hide-details":"","dense":"","solo":""},on:{"change":_vm.fetchData},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"btn-starkoms-primary",staticStyle:{"min-width":"138px"},attrs:{"dark":""}},[_vm._v("Buscar")])],1)])],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1 datatable",attrs:{"headers":_vm.headers,"items":_vm.tableData,"page":_vm.page,"items-per-page":_vm.selectItemsPerPage,"loading":_vm.dataTableLoading,"no-results-text":"No hay resultados disponibles","no-data-text":"No hay datos disponibles","loading-text":"Cargando datos...","disable-pagination":true,"hide-default-footer":true},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format('LLLL'))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",staticStyle:{"font-size":"1.5rem !important"},attrs:{"small":"","color":"#8c8c8c"},on:{"click":function($event){return _vm.openDialogBrandEdit({brand_id: item.id, brand_name: item.name})}}},on),[_vm._v("mdi-circle-edit-outline")])]}}],null,true)},[_c('span',[_vm._v("Editar Marca")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",staticStyle:{"font-size":"1.5rem !important"},attrs:{"small":"","color":"#8c8c8c"},on:{"click":function($event){return _vm.softDelete(item)}}},on),[_vm._v("mdi-package-down")])]}}],null,true)},[_c('span',[_vm._v("Archivar")])])]}}])}),_c('div',{staticClass:"d-flex justify-space-between mt-4"},[_c('v-select',{staticStyle:{"max-width":"120px"},attrs:{"label":"Registros por página","items":[5,10,20,50,100, 500]},on:{"input":_vm.fetchData},model:{value:(_vm.selectItemsPerPage),callback:function ($$v) {_vm.selectItemsPerPage=$$v},expression:"selectItemsPerPage"}}),_c('v-pagination',{attrs:{"length":_vm.totalPages,"total-visible":100,"color":"#e55d43"},on:{"input":_vm.fetchData},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1),_c('dialog-brand-edit',{on:{"success":_vm.fetchData}}),_c('bulk-upload-brands',{on:{"success":_vm.fetchData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }