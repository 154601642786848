<template>
<v-app>
    <v-dialog v-model="showDialog" width="600">
        <v-card>
            <v-card-title class="text-h5">
                <span v-if="brand_id == undefined">Crear Marca</span>
                <span v-else>Editar Marca</span>
            </v-card-title>
            <v-card-text>
            <v-row>
                <v-col cols="12" sm="12" md="12">
                <v-text-field
                    v-model="brand_name"
                    label="Marca"
                    :rules="[v => !!v || 'Ingresar un nombre es requerido!']"
                ></v-text-field>
                </v-col>
        
            </v-row>

            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="success"   
                    @click="sendForm"
                    >
                    Guardar
                </v-btn>
               <v-btn
                    color="primary"
                    @click="showDialog = false"
                    >
                    Cerrar
                </v-btn>
            </v-card-actions>
            
        </v-card>
    </v-dialog>
</v-app>
</template>

<script>
export default {
    data(){
        return {
            showDialog: false,
            brand_id: undefined,
            brand_name: undefined,
            parent_category : undefined,
            categories: [],
            categoriesList: [],
        }
    },
    created(){
        
        this.$parent.$on('openDialogBrandEdit', (data) => {
            this.brand_id = data.brand_id;
            this.brand_name = data.brand_name;
        
            this.showDialog = true; 
           

           
        });
    },
    methods: {
        sendForm(){
            var vm = this;
            if (vm.brand_id == undefined) {
                
                this.axios({
                     url: "inventory/brands",
                    method: "POST",
                    data: {
                        name: vm.brand_name
                    },
                })
                .then((response) => {
                    vm.showDialog = false;
                        vm.$bvToast.toast('Marca creada correctamente', {
                        title: `Información`,
                        variant: 'success',
                        solid: true,
                        toaster: 'b-toaster-bottom-center'
                    });
                    vm.$emit('success', "Marca creada correctamente");
                })
                .catch((error) => {
                console.log(error);
                });

            }
            else{
                this.axios({
                    url: "inventory/brands/" + vm.brand_id,
                    method: "PUT",
                    data: {
                        name: vm.brand_name
                    },
                })
                .then((response) => {
                    vm.showDialog = false;
                        vm.$bvToast.toast('Marca actualizada correctamente', {
                        title: `Información`,
                        variant: 'success',
                        solid: true,
                        toaster: 'b-toaster-bottom-center'
                    });
                    vm.$emit('success', "Marca modificado correctamente");
     
                })
                .catch((error) => {
                console.log(error);
                });
            }
            
        },
    }
}
</script>