<template>
    <v-dialog v-model="showDialog">
        <v-card>
            <v-card-title>
                <span>Subir archivo de carga masiva</span>
                <v-spacer></v-spacer>
                <v-btn href="/storage/brands_bulk_upload.xlsx" target="_blank" class="ma-2" tile outlined color="success">
                <v-icon left>mdi-download-outline</v-icon> Descargar archivo formato excel
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-file-input
                v-model="file"
                color="deep-purple accent-4"
                placeholder="Sube un archivo formato .xslx"
                prepend-icon="mdi-paperclip"
                outlined
                :show-size="1000"
                :disabled="loading"
                >
                    <template v-slot:selection="{ index, text }">
                        <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                        >
                        {{ text }}
                        </v-chip>
                    </template>
                </v-file-input>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="blue darken-1" @click="showDialog = false">Cancelar</v-btn>
                <v-spacer></v-spacer>
                <v-btn dark color="success" :disabled="!readyForUpload || loading" @click="sendForm" >Subir</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data(){
        return {
            showDialog: false,
            file: undefined,
            loading: false,
        }
    },
    created(){
        this.$parent.$on('openBulkUploadBrands', () => {
            this.showDialog = true;
        });
    },
    methods:{
        sendForm(){
            this.loading = true;
            this.$nextTick(() => {
                let formData = new FormData();
                formData.append("file", this.file, this.file.name);
                var vm = this;
                this.axios({
                    url: 'inventory/brands/bulk_upload',
                    method: 'POST',
                    data: formData,
                }).then( () => {
                    /*
                    vm.$bvToast.toast(
                        'Archivo subido con exito',
                        {
                            title: `Notificación`,
                            variant: "success",
                            solid: true,
                            toaster: "b-toaster-bottom-center",
                        }
                    );
                    */
                    vm.loading = false;
                    vm.showDialog = false;
                    vm.$emit("success");
                }).catch( error => {
                    vm.loading = false;
                });
            })
                
        }
    },
    computed:{
        readyForUpload(){
            return this.file instanceof File ? true : false;
        }
    }
}
</script>